/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, ::before, ::after {
  box-sizing: inherit;
} 

body {
	font-family: 'lato';
	font-size: 1.1em;
	line-height: 1.5em;
	margin: 2em auto;
	padding-left: 1em;
	padding-right: 1em;
	max-width: 35em;
}

h1, h2, h3, h4, h5 {
	line-height: 1.3em;
}

/**
 * Images
 */
img {
    width: 100%;
    max-width: 800px;
    display: block;
  margin-left: auto;
  margin-right: auto;
}

/**
* Post style
*/
.post-bio p {
	color: lighten(black, 25%);
	font-style: italic;
}

.highlighter-rouge {
	background-color: #E8EAF6;
}

code {
	overflow: auto;
	display: block;
	overflow-wrap: break-word;
}