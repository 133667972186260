@charset "utf-8";

@import 'vendor/normalize';

@import 'base/root';


table {
border-collapse: collapse;
}
th, td {
border: 1px solid #ccc;
padding: 10px;
text-align: left;
}
tr:nth-child(even) {
background-color: #eee;
}
tr:nth-child(odd) {
background-color: #fff;
}            
